import * as React from "react";
import { alpha } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import heroBookings from "../assets/images/hero-bookings.png";

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        backgroundImage:
          theme.palette.mode === "light"
            ? "linear-gradient(180deg, #CEE5FD, #FFF)"
            : `linear-gradient(#02294F, ${alpha("#090E10", 0.0)})`,
        backgroundSize: "100% 20%",
        backgroundRepeat: "no-repeat",
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
          maxWidth: "lg", // Ensure the container doesn't exceed this width
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              flexWrap: "wrap", // Allow text to wrap if necessary
              fontSize: { xs: "1.8rem", sm: "2.5rem", md: "3rem" },
              width: "100%",
              maxWidth: "md", // Ensure it doesn't overflow the container
              margin: "0 auto", // Center the box within the container
            }}
          >
            <Typography
              variant="h1"
              component="span"
              sx={{
                fontSize: "inherit", // Ensure consistent font size
                whiteSpace: { xs: "normal", md: "nowrap" }, // Allow wrapping on small screens
              }}
            >
              Fly Smarter, Manage Better with&nbsp;
            </Typography>
            <Typography
              variant="h1"
              component="span"
              sx={{
                color: (theme) =>
                  theme.palette.mode === "light"
                    ? "primary.main"
                    : "primary.light",
                fontSize: "inherit", // Ensure consistent font size
                whiteSpace: { xs: "normal", md: "nowrap" }, // Allow wrapping on small screens
              }}
            >
              eDispatched 135
            </Typography>
          </Box>

          <Typography
            textAlign="center"
            color="text.secondary"
            variant="subtitle1"
            sx={{ alignSelf: "center", width: { sm: "100%", md: "80%" } }}
          >
            Effortlessly manage your 135 operation with our intuitive software.
            Enjoy seamless ticketing, reservations, payment processing, fleet
            management, and AI-driven maintenance—all just a click away. Focus
            on what you do best while we handle the rest
          </Typography>
        </Stack>
        <Box
          id="image"
          sx={(theme) => ({
            mt: { xs: 8, sm: 10 },
            alignSelf: "center",
            height: { xs: 200, sm: 700 },
            width: "100%",
            backgroundImage: `url(${heroBookings})`,
            backgroundSize: "cover",
            borderRadius: "10px",
            outline: "1px solid",
            outlineColor:
              theme.palette.mode === "light"
                ? alpha("#BFCCD9", 0.5)
                : alpha("#9CCCFC", 0.1),
            boxShadow:
              theme.palette.mode === "light"
                ? `0 0 12px 8px ${alpha("#9CCCFC", 0.2)}`
                : `0 0 24px 12px ${alpha("#033363", 0.2)}`,
          })}
        />
      </Container>
    </Box>
  );
}
