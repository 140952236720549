// theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: ["Open Sans", "Arial", "sans-serif"].join(","),
    h1: {
      fontFamily: ["Open Sans", "Arial", "sans-serif"].join(","),
      fontWeight: 700,
    },
    h2: {
      fontFamily: ["Open Sans", "Arial", "sans-serif"].join(","),
      fontWeight: 700,
    },
    h3: {
      fontFamily: ["Open Sans", "Arial", "sans-serif"].join(","),
      fontWeight: 700,
    },
    h4: {
      fontFamily: ["Open Sans", "Arial", "sans-serif"].join(","),
      fontWeight: 700,
    },
    h5: {
      fontFamily: ["Open Sans", "Arial", "sans-serif"].join(","),
      fontWeight: 700,
    },
    h6: {
      fontFamily: ["Open Sans", "Arial", "sans-serif"].join(","),
      fontWeight: 700,
    },
    body1: {
      fontFamily: ["Open Sans", "Arial", "sans-serif"].join(","),
      fontWeight: 400,
    },
    body2: {
      fontFamily: ["Open Sans", "Arial", "sans-serif"].join(","),
      fontWeight: 400,
    },
    caption: {
      fontFamily: ["Open Sans", "Arial", "sans-serif"].join(","),
      fontWeight: 400,
    },
    subtitle1: {
      fontFamily: ["Open Sans", "Arial", "sans-serif"].join(","),
      fontWeight: 400,
    },
    subtitle2: {
      fontFamily: ["Open Sans", "Arial", "sans-serif"].join(","),
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#dc004e",
    },
  },
});

export default theme;
