// App.js
import React from "react";
import { ThemeProvider, CssBaseline, GlobalStyles } from "@mui/material";
import theme from "./utils/theme";
import LandingPage from "./LandingPage";

const globalStyles = (
  <GlobalStyles
    styles={{
      body: {
        margin: 0,
        padding: 0,
        fontFamily: "Open Sans, Arial, sans-serif",
      },
    }}
  />
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {globalStyles}
      <LandingPage />
    </ThemeProvider>
  );
}

export default App;
